.header__navbar--reactStrapOverride{
    justify-content: space-between;
    flex-wrap: nowrap;
    background-color: #131c29;
}

.header__brandImg{
    height: 35px;
    width: 75px;
}

.header__btnContainer{
    width: 100%;
    background-color: #091423;
    border-radius: 0 0 20px 20px;
    height: 75px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header__profileIcon{
    font-size: 30px;
    cursor: pointer;
    color: white;
    padding: .5rem 1rem;
}

.header__login, .header__login:hover{
    color: white;
    padding: .5rem 1rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 25px;
    text-decoration: none;
}


/* ==========================================

    ReactStrap Override Classes

============================================ */
.popover{
    background-color: #3D4D65;
    color: white;
}

.popover-header{
    background-color: #3D4D65;
    border-bottom-color: #44aefb;
    color: #FB9C44;
}

.popover-body{
    color: white;
}

.popover-body > button{
    border-color: #D2335c;
    background-color: #D2335c;
    border-radius: 20px;
    color: white;
    font-weight: bold;
}

.bs-popover-auto[x-placement^=bottom]>.arrow::after,
.bs-popover-auto[x-placement^=bottom] .popover-header::before{
    border-bottom-color: #3D4D65;
}

@media (min-width: 813px) {

    .header__brandImg{
        height: 69px;
        width: 150px;
    }

}