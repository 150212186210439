a, a:hover{
    color: white;
    text-decoration: none;
}

.sideNav__icon{
    position: relative;
    top: 6px;
    left: -3px;
    font-size: 30px;

}

.sideNav__container{
    margin-top: 75px;
}

.sideNav__label{
    display: block;
    color: white;
    font-size: 0;
    text-align: left;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
}

.sideNav__selected{
    background-color: #3D4D65;
    display: block;
    color: white;
    font-size: 0;
    text-align: left;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
}

.sideNav__selected > i{
    border-left: 3px solid #44aefb;
}


@media (min-width: 813px) {
    .sideNav__label{
        font-size: 26px;
    }
}