.App {
  text-align: center;
}

.main{
  background-color: #131c29;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: .5rem 1rem;
}

.main__side-menu{
  height:inherit;
  min-width: 36px;
  margin-right: 1rem;
}

.main__content{
  background-color: #091423;
  color: white;
  width: 100%;
  float: right;
  padding-top: 20px;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}

.main__content > h1{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 40px;
}

@media (min-width: 813px) {

  .main{
    height: 100vh;
  }
  .main__side-menu{
    min-width: 150px;
  }

}