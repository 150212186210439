.domain__listContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.domain__addDomain{
    margin-top: 15px;
    font-size: 60px;
    cursor: pointer;
}

.singleDomain{
    display: flex;
    flex-direction: row;
    width: 70vw;
    margin: 7px auto;
    align-items: center;
    justify-content: space-around;
    background-color: #182434;
    border-radius: 20px;
}

.singleDomain__content{
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.singleDomain__nameAndStatus{
    padding-top: 8px;
    text-align: left;
}

.singleDomain__nameAndStatus > h1{
    font-size: 26px;
    font-family: 'Roboto Condensed', sans-serif;
}

.singleDomain__nameAndStatus > p{
    color: #45f957;
    font-family: 'Roboto', sans-serif;
}

.singleDomain__siteURL{
    display: none;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}

.singleDomain__createdOn{
    display: none;
    font-family: 'Roboto', sans-serif;
}

.singleDomain__divider{
    display: none;
}

.singleDomain__detailsContainer{
    margin-right: 5px;
}

.singleDomain__detailsIcon{
    display: block;
}

.singleDomain__detailsBtn{
    display: none;
}

.modal-content{
    margin-top: 150px;
    background-color: #3D4D65;
}

.modal-header{
    border-bottom-color: #44aefb;
    font-family: 'Roboto Condensed', sans-serif;
}

.modal-header > h5{
    font-weight: 700;
    font-size: 22px;
}

.modal-title{
    color: #FB9C44;
}

.modal-footer{
    border-top-color: #44aefb;
}

.save-btn{
    color: black;
    font-family: 'Roboto', sans-serif;
    background-color: #45f957;
    border-color: #45f957;
}

.save-btn:hover{
    color: black;
    font-family: 'Roboto', sans-serif;
    background-color: #06c619;
    border-color: #06c619;
}

.cancel-btn{
    color: black;
    font-family: 'Roboto', sans-serif;
    background-color: #D2335c;
    border-color: #D2335c;
}

.cancel-btn:hover{
    color:black;
    font-family: 'Roboto', sans-serif;
    background-color: #bc294e;
    border-color: #bc294e;
}

@media (min-width: 813px) {

    .singleDomain__siteURL{
        display: block;
        min-width: 20vw;
        max-width: 20vw;
        word-wrap: break-word;
    }

    .singleDomain__siteURL > a{
        color: #4CD6C1;
    }

    .singleDomain__createdOn{
        padding-top: 8px;
        display: block;
    }

    .singleDomain__createdOn > h3{
        font-size: 24px;
    }

    .singleDomain__divider{
        display: block;
        width: 2px;
        height: 60px;
        background-color: #091423;
        border-radius: 20px;
    }

    .singleDomain__detailsContainer{
        margin-right: 5px;
    }

    .singleDomain__detailsContainer > a:hover{
        margin-right: 5px;
        text-decoration: none;
    }

    .singleDomain__detailsIcon{
        display: none;
    }

    .singleDomain__detailsBtn, .singleDomain__detailsBtn:hover{
        display: block;
        background-color: #44aefb;
        color: white;
        font-weight: bold;
        border-radius: 20px;
        width: 110%;
        padding: 2px 9px 3px;
        border: 1px solid white;
        text-decoration: none;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }

    .singleDomain{
        width: 80%;
    }

    .singleDomain__nameAndStatus{
        text-align: left;
        width: 20vw;
    }

}