.landing-welcome_text > h1{
    color: white;
    font-size: 40px;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
}

.landing-welcome_text > p{
    color: white;
    font-family: 'Roboto', sans-serif;
}

#background-video{
    opacity: 0.15;
    border-radius: 20px 20px 0 0;
    width: 100%;
}

.landing-video_container{
    width: 100%;
    height: 35vh;

}

.landing-welcome_text{
    position: relative;
    top: -18vh;
    left: 0vw;
    right: 0;
    bottom: 0;
    z-index: 99;
}

.testing{
    overflow: hidden;
}

.landing-info_card{
    width: 315px;
    color: black;
    box-shadow: 0px 1px 15px 3px #7099A6;
    padding: 10px;
    margin: 10px;
}

.landing-info_cards_container{
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.account-btn{
    background-color: #44aefb;
    border-radius: 15px;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-weight: 500;
}

.cardTitle{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
}

.cardText{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.buffer{
    height: 150px;
}

@media (min-width: 813px) {


}